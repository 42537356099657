<template>
  <div>
    <v-dialog v-model="showImageDialog" width="600" persistent hide-overlay transition="dialog-bottom-transition">
      <v-card color="alt" class="dialog-header">
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="showImageDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>تغيير حالة الهوية</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>

        <v-card-text>
          <div style="margin: 15px">
            <v-autocomplete v-model="student.statusId" :items="statusIds" item-text="name" item-value="id" solo label="الحالة"></v-autocomplete>
          </div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn color="primary" text @click="saveData"> موافق </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-icon @click="showImageDialog = true" color="primary"> mdi-pencil </v-icon>
  </div>
</template>

<script>
export default {
  props: {
    student: Object,
  },
  data: () => ({
    datePickerAddModel: false,
    showImageDialog: false,
    addedDate: "",

    files: [],
    statusIds: [
      { name: "قيد الطباعة", id: 1 },
      { name: "تمت الطباعة", id: 2 },
      { name: "تم الرفض", id: 3 },
    ],
  }),
  methods: {
    saveData() {
      let self = this;
      self.$http.put(`${self.$store.state.apiUrl}/studentIdentityFormStatus/${self.student.idStudentIdentityForm}`, { statusId: self.student.statusId }).then((res) => {
        console.log(res.data);
        this.$toasted.success("تم تعديل البيانات");
        this.$emit("reloadTask");
        self.showImageDialog = false;
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
