<template>
  <div>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-dialog v-model="searchDialog" persistent max-width="700px" width="700px">
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="searchDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>بحث في الطلبة</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>

        <v-card-text>
          <div style="margin-top: 20px"></div>

          <v-autocomplete v-model="sectionId" :items="sections" item-text="sectionName" item-value="idSection" outlined label="القسم"></v-autocomplete>

          <v-autocomplete v-model="level" :items="levels" item-text="levelName" item-value="level" outlined label="المرحلة"></v-autocomplete>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn color="primary" text @click="searchStudentPaid" v-on:keyup.enter="searchStudentPaid"> بحث </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn class="mx-2" fab small color="primary" @click="searchDialog = true" v-bind="attrs" v-on="on">
          <v-icon> mdi-magnify </v-icon>
        </v-btn>
      </template>
      <span>بحث</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  props: {
    sections: Array,
  },
  data: () => ({
    searchDialog: false,
    sectionId: "",
    typeOfPayId: "",
    paymentTypeId: "",
    studentRequiredFormRules: [(v) => !!v || "حقل مطلوب . يرجى ادخال قيمة"],
    studentName: "",
    collegeNumber: "",
    overlay: false,
    typeOfPay: [
      { name: "المسددين", id: 1 },
      { name: "غير مسددين", id: 2 },
      { name: "الجميع", id: 3 },
    ],
    levels: [
      { levelName: "المرحلة الاولى", level: 1 },
      { levelName: "المرحلة الثانية", level: 2 },
      { levelName: "المرحلة الثالثة", level: 3 },
      { levelName: "المرحلة الرابعة", level: 4 },
      { levelName: "المرحلة الخامسة", level: 5 },
      { levelName: "جميع المراحل", level: 10 },
    ],
    level: "",
    allSectionInstallments: [],
  }),
  created() {},
  methods: {
    searchStudentPaid() {
      let self = this;
      let query = "studentStatusId = 1";

      if (self.sectionId) {
        query += `&sectionId=${self.sectionId}`;
      }

      if (self.level) {
        query += `&level=${self.level}`;
      }

      self.$http
        .get(`${self.$store.state.apiUrl}/v2/paidStudents?${query}`, {
          headers: { Authorization: `Bearer ${self.$store.state.user}` },
        })
        .then((res) => {
          console.log(res.data);
          self.overlay = false;
          self.searchDialog = false;
          let studentPay = res.data[0].map((student) => {
            return {
              studentName: student.studentName,
              level: student.level,
              idStudent: student.idStudent,
              acceptedName: student.acceptedName,
              studyType: student.studyType,
              sectionName: student.sectionName,
              discount: student.discount,
              collegeNumber: student.collegeNumber,
              mail: student.mail,
              dob: student.dob,
              gender: student.gender,
            };
          });
          console.log(studentPay);
          let data = {
            paymentTypeId: self.paymentTypeId,
            students: studentPay,
          };
          this.$emit("clicked", data);
        })
        .catch((err) => {
          console.log(err);
          self.overlay = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
