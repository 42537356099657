<template>
  <div>
    <v-dialog v-model="showImageDialog" width="800" persistent hide-overlay transition="dialog-bottom-transition">
      <v-card color="alt" class="dialog-header">
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="showImageDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>المرفقات</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>

        <v-card-text>
          <div style="margin: 15px">
            <v-row>
              <v-col cols="6">
                <img
                  v-if="!getFileInfo(student.identityFace)"
                  crossOrigin="anonymous"
                  @click="downloadWithAxios(`${$store.state.apiUrl}/${student.identityFace}`, `${student.studentName}.${student.identityFace.split('.').pop()}`)"
                  :src="`${$store.state.apiUrl}/${student.identityFace}`"
                  width="330"
                  height="500"
                />
              </v-col>
              <v-col cols="6">
                <img
                  v-if="!getFileInfo(student.identityBack)"
                  crossOrigin="anonymous"
                  @click="downloadWithAxios(`${$store.state.apiUrl}/${student.identityBack}`, `${student.studentName}.${student.identityBack.split('.').pop()}`)"
                  :src="`${$store.state.apiUrl}/${student.identityBack}`"
                  width="330"
                  height="500"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="6">
                <img
                  v-if="!getFileInfo(student.residentFace)"
                  crossOrigin="anonymous"
                  @click="downloadWithAxios(`${$store.state.apiUrl}/${student.residentFace}`, `${student.studentName}.${student.residentFace.split('.').pop()}`)"
                  :src="`${$store.state.apiUrl}/${student.residentFace}`"
                  width="330"
                  height="500"
                />
              </v-col>
              <v-col cols="6">
                <img
                  v-if="!getFileInfo(student.residentBack)"
                  crossOrigin="anonymous"
                  @click="downloadWithAxios(`${$store.state.apiUrl}/${student.residentBack}`, `${student.studentName}.${student.residentBack.split('.').pop()}`)"
                  :src="`${$store.state.apiUrl}/${student.residentBack}`"
                  width="330"
                  height="500"
                />
              </v-col>
            </v-row>
          </div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn color="primary" text @click="showImageDialog = false"> موافق </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- <v-avatar size="100">
      <img :src="image" @click="showImageDialog = true" />
    </v-avatar> -->
    <v-icon @click="showImageDialog = true" color="primary"> mdi-image-area </v-icon>
  </div>
</template>

<script>
import { jsPDF } from "jspdf";
import VuePdfEmbed from "vue-pdf-embed/dist/vue2-pdf-embed";

export default {
  components: {
    VuePdfEmbed,
  },
  props: {
    image: String,
    student: Object,
  },
  data: () => ({
    datePickerAddModel: false,
    showImageDialog: false,
    addedDate: "",
    files: [],
  }),
  methods: {
    confirmDelete() {
      let self = this;
      self.$http.delete(`${self.$store.state.apiUrl}/archive/${self.dataToDelete.idArchive}`).then((res) => {
        console.log(res.data);
        this.$toasted.error("تم حذف البيانات");
        self.showDeleteDialog = false;
        this.$emit("reloadTask");
      });
    },
    getFileInfo(file) {
      let isPdf = file.includes(".pdf");
      console.log(isPdf);
      return isPdf;
    },
    forceFileDownload(response, title) {
      console.log(title);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", title);
      document.body.appendChild(link);
      link.click();
    },
    downloadWithAxios(url, title) {
      this.$http({
        method: "get",
        url,
        responseType: "arraybuffer",
      })
        .then((response) => {
          this.forceFileDownload(response, title);
        })
        .catch(() => console.log("error occured"));
    },
  },
};
</script>

<style lang="scss" scoped></style>

<!-- <VirtualHost *:80>
    ServerName abro-store.com
    ServerAdmin admin@abro-store.com
    DocumentRoot /var/www/html/abro_18_8_2023_last_v_backend_laravel/public
    <Directory /var/www/html/abro_18_8_2023_last_v_backend_laravel>
    AllowOverride All
    </Directory>
    ErrorLog ${APACHE_LOG_DIR}/error.log
    CustomLog ${APACHE_LOG_DIR}/access.log combined
</VirtualHost> -->

<!-- a2ensite abro.conf

a2enmod rewrite
systemctl restart apache2

sudo chown -R www-data:www-data /var/www/html/abro_18_8_2023_last_v_backend_laravel


mustafaabro phpmyadmin password -->
