<template>
  <div>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-dialog v-model="searchDialog" persistent max-width="700px" width="700px">
      <v-card v-if="$store.state.sections != null">
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="searchDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>تعديل الطالب</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>

        <v-card-text>
          <div style="margin-top: 20px"></div>
          <v-row>
            <v-col cols="12">
              <v-autocomplete v-model="student.gender" :items="gender" item-text="name" item-value="name" outlined label="جنس الطالب"></v-autocomplete>

              <v-autocomplete v-model="student.studyType" :items="studyTypes" item-text="name" item-value="studyType" outlined label="نوع الدراسة"></v-autocomplete>

              <v-autocomplete v-model="student.sectionName" :items="$store.state.allSections" outlined label="القسم"></v-autocomplete>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn color="primary" text @click="editStudent" v-on:keyup.enter="editStudent"> تعديل </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon color="primary" @click="searchDialog = true" v-bind="attrs" v-on="on">
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
      </template>
      <span>تعديل الطالب</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  props: {
    student: Object,
  },
  data: () => ({
    searchDialog: false,
    studentRequiredFormRules: [(v) => !!v || "حقل مطلوب . يرجى ادخال قيمة"],
    gender: [
      { name: "ذكر", id: 1 },
      { name: "انثى", id: 2 },
    ],
    studyTypes: [
      { name: "صباحي", studyType: 1 },
      { name: "مسائي", studyType: 2 },
    ],
    sectionName: "",
    overlay: false,
  }),
  methods: {
    editStudent() {
      let self = this;
      let studentData = {
        studentName: self.student.studentName,
        collegeNumber: self.student.collegeNumber,
        studentEnglishName: self.student.studentEnglishName,
        sectionName: self.student.sectionName,
        sectionEnglishName: self.student.sectionEnglishName,
        dob: self.student.dob,
        studentEmail: self.student.studentEmail,
        nationality: self.student.nationality,
        gender: self.student.gender,
        studyType: self.student.studyType,
        studentLevel: self.student.studentLevel,
        studentClass: self.student.studentClass,
        statusId: self.student.statusId,
        photoPath: self.student.photoPath,
      };

      self.$http
        .put(`${self.$store.state.apiUrl}/studentIdentityForm/${self.student.idStudentIdentityForm}`, studentData)
        .then((res) => {
          this.$toasted.success("تم تعديل البيانات");
          self.overlay = false;
          self.$emit("reloadTask");
          self.searchDialog = false;
        })
        .catch((error) => {
          self.overlay = false;
          self.searchDialog = false;
          this.$toasted.error("حدث خطأ الرجاء التأكد من المعلومات");
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
