import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    apiUrl: "https://student.janat-albelad.com/api",
    apiUrlEmployee: "https://employee.janat-albelad.com/api",
    user: null,
    userData: null,
    sections: null,
    allStudents: null,
    allSections: [
      "تقنية صناعة الاسنان",
      "تقنيات التخدير",
      "تقنيات المختبرات الطبية",
      "تقنيات الاشعة",
      "تقنيات البصريات",
      "التربية البدنية وعلوم الرياضة",
      "مالية ومصرفية",
      "المحاسبة",
      "ادارة اعمال",
      "علوم السياحة و الفندقة",
      "هندسة تقنيات الحاسوب",
      "تقنيات التبريد والتكيف",
      "تقنيات اجهزة طبية",
      "هندسة القدرة الكهربائية",
      "الصيدلة",
      "القانون",
      "اللغة الانكليزية",
      "علوم الحاسوب",
      "الادلة الجنائية",
      "علوم الامن السيبراني",
      "علوم الحياة",
      "الذكاء الاصطناعي",
      "الدراسات الاسلامية",
      "العلوم النفسية والتربوية",
      "الطب الحياتي",
      "هندسة تكرير النفط والغاز",
      "تقنيات الطيران",
      "الاعلام",
      "التصميم",
      "طب الاسنان",
      "التمريض",
    ],
  },
  getters: {},
  mutations: {
    GET_USER_DATA(state, userData) {
      state.user = userData;
    },
  },
  actions: {
    getUserDate({ commit }) {
      let data = localStorage.getItem("formAdmin");

      if (data) {
        var base64Url = data.split(".")[1];
        var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
        var jsonPayload = decodeURIComponent(
          atob(base64)
            .split("")
            .map(function (c) {
              return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join("")
        );

        let text = JSON.parse(jsonPayload);
        console.log(text);
        this.state.userData = text;
      }
      commit("GET_USER_DATA", JSON.parse(data));
    },
  },
  modules: {},
});
