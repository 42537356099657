import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import Login from "../views/Login.vue";
import Report from "../views/Report.vue";
import Students from "../views/Students.vue";
import StudentsInformation from "../views/StudentsInformation.vue";
import FastService from "../views/FastService.vue";
import LevelOneStudents from "../views/LevelOneStudents.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/login",
    name: "login",
    component: Login,
  },

  {
    path: "/one",
    name: "LevelOneStudents",
    component: LevelOneStudents,
  },
  {
    path: "/info",
    name: "StudentsInformation",
    component: StudentsInformation,
  },
  {
    path: "/fast",
    name: "fastService",
    component: FastService,
  },
  {
    path: "/students",
    name: "students",
    component: Students,
  },
  {
    path: "/report",
    name: "report",
    component: Report,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
