<template>
  <v-app>
    <v-navigation-drawer v-model="drawer" app class="pt-4 not-printable" color="primary" mini-variant fixed right v-if="$store.state.user != null">
      <v-list>
        <v-subheader></v-subheader>
        <v-list-item-group v-model="selectedItem" color="accent">
          <v-list-item v-for="(item, i) in items" :key="i" style="padding: 10px 15px" @click="page(item)">
            <v-tooltip left color="primary">
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" color="white">{{ item.icon }}</v-icon>
              </template>
              <span>{{ item.text }}</span>
            </v-tooltip>
          </v-list-item>
        </v-list-item-group>
      </v-list>

      <template v-slot:append>
        <div class="pa-2">
          <v-icon left color="white" @click="logoutPage()"> mdi-account-circle </v-icon>
        </div>
      </template>
    </v-navigation-drawer>

    <v-app-bar app color="primary" v-if="$store.state.user != null" class="not-printable">
      <div class="d-flex align-center" style="width: 100%">
        <div style="text-align: right">
          <v-avatar color="secondary" size="50">
            <v-img :src="require('./assets/logo.png')" class="my-3" contain />
          </v-avatar>
        </div>
        <v-spacer></v-spacer>
        <h5 style="color: aliceblue">برنامج الهويات للحوكمة</h5>
        <v-spacer></v-spacer>
        <h5 @click="logoutPage()" style="color: aliceblue">{{ $store.state.userData.employeeName }}</h5>
      </div>
    </v-app-bar>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",

  data: () => ({
    selectedItem: 0,
    drawer: true,

    offset: true,
    login: false,
    items: [
      { title: "/", text: "الرئيسية", icon: "mdi-home" },
      // {
      //   title: "/students",
      //   text: "طلبة المراحل",
      //   icon: "mdi-account-group-outline",
      // },
      {
        title: "/one",
        text: "الطلبة",
        icon: "mdi-account-group-outline",
      },
      {
        title: "/info",
        text: "طلبة الجامعة",
        icon: "mdi-account-multiple",
      },
      {
        title: "/fast",
        text: "الخدمة السريعة",
        icon: "mdi-file-sign",
      },
      {
        title: "/report",
        text: "التقارير",
        icon: "mdi-chart-bar-stacked",
      },
    ],
  }),
  created() {
    this.$store.dispatch("getUserDate").then(() => {
      if (this.$store.state.user) {
        this.login = true;
        this.$router.push("/").catch(() => {});
      } else {
        this.$router.push("/login").catch(() => {});
        this.login = false;
      }
    });
  },
  methods: {
    logoutPage() {
      localStorage.removeItem("formAdmin");
      location.reload();
    },
    page(item) {
      this.$router.push(item.title).catch(() => {});
    },
    logout() {
      localStorage.removeItem("employee");
      location.reload();
    },
  },
};
</script>
<style>
@import url("./font.css");
</style>
