<template>
  <div>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-dialog v-model="searchDialog" persistent max-width="700px" width="700px">
      <v-card v-if="$store.state.sections != null">
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="searchDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>انشاء تقرير</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>

        <v-card-text>
          <div style="margin-top: 20px"></div>
          <v-row>
            <v-col cols="12">
              <v-autocomplete v-model="statusId" :items="allStatus" item-text="name" item-value="id" outlined label="حالة الهوية"></v-autocomplete>

              <v-autocomplete v-model="sectionName" :items="$store.state.sections" item-text="sectionName" item-value="sectionName" outlined label="القسم"></v-autocomplete>

              <!-- <v-autocomplete v-model="selectedLevel" :items="levels" item-text="levelName" item-value="level" outlined label="المرحلة"></v-autocomplete> -->
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn color="primary" text @click="searchData" v-on:keyup.enter="searchData"> بحث </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn class="mx-2" fab small color="primary" @click="searchDialog = true" v-bind="attrs" v-on="on">
          <v-icon> mdi-magnify </v-icon>
        </v-btn>
      </template>
      <span>بحث</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  props: {
    students: Array,
  },
  data: () => ({
    searchDialog: false,
    statusId: "",
    studentRequiredFormRules: [(v) => !!v || "حقل مطلوب . يرجى ادخال قيمة"],
    selectedLevel: "",
    allStatus: [
      { name: "قيد الطباعة", id: 1 },
      { name: "تمت طباعتها", id: 2 },
      { name: "تم رفضها", id: 3 },
    ],
    levels: [
      { levelName: "المرحلة الاولى", level: 1 },
      { levelName: "المرحلة الثانية", level: 2 },
      { levelName: "المرحلة الثالثة", level: 3 },
      { levelName: "المرحلة الرابعة", level: 4 },
      { levelName: "المرحلة الخامسة", level: 5 },
      { levelName: "جميع المراحل عدا الاول", level: 10 },
    ],
    sectionName: "",
    overlay: false,
  }),
  methods: {
    searchData() {
      let self = this;

      this.$emit("clicked", { sectionName: self.sectionName, statusId: self.statusId });

      this.searchDialog = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
