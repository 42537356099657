<template>
  <div>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-container class="container" fluid>
      <v-card elevation="6">
        <v-row style="margin: 10px">
          <v-col cols="10">
            <h3>صفحة الطلبة</h3>
          </v-col>
          <v-col cols="2" style="text-align: left">
            <FilterStudents style="display: inline-block" :students="students" @clicked="onSearch" />
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn class="mx-2" fab small color="primary" @click="exportExcel()" v-bind="attrs" v-on="on">
                  <v-icon> mdi-microsoft-excel </v-icon>
                </v-btn>
              </template>
              <span>تصدير اكسل</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn class="mx-2" fab small color="primary" @click="downloadImages()" v-bind="attrs" v-on="on">
                  <v-icon> mdi-download-circle-outline </v-icon>
                </v-btn>
              </template>
              <span>تحميل الصور</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-card>
    </v-container>

    <v-container class="container" fluid>
      <v-card elevation="6">
        <v-card-title>
          <v-text-field v-model="search" @change="searchStudent()" v-on:keyup.enter="searchStudent()" outlined append-icon="mdi-magnify" label="بحث" single-line hide-details></v-text-field>
        </v-card-title>
        <v-data-table :headers="headers" :items="students" :items-per-page="30" :search="search" item-key="idStudent" class="table-content" :no-data-text="dataMessage">
          <template v-slot:[`item.photo`]="{ item }">
            <div :style="setColor(item.statusId)">
              <StudentImage :image="foundImage(item.photoPath) ? item.photoPath : require('./../assets/logo.png')" :student="item" style="display: inline-block" />
            </div>
          </template>
          <template v-slot:[`item.gender`]="{ item }">
            <h4 style="padding: 0 3px">
              {{ item.gender }}
            </h4>
          </template>

          <template v-slot:[`item.statusId`]="{ item }">
            <h4 style="padding: 0 3px">
              {{ item.statusId == 1 ? "قيد الطباعة" : item.statusId == 2 ? "طبعت" : "تم الرفض" }}
            </h4>
          </template>

          <template v-slot:[`item.studentLevel`]="{ item }">
            <h4 style="padding: 0 3px">
              {{ item.studentLevel | setLevel }}
            </h4>
          </template>

          <template v-slot:[`item.studyType`]="{ item }">
            <h4 style="padding: 0 3px">
              {{ item.studyType == 1 ? "صباحي" : "مسائي" }}
            </h4>
          </template>

          <template v-slot:[`item.college`]="{ item }">
            <p style="padding: 0 3px">
              {{ getCollege(item) }}
            </p>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <v-row>
              <v-col cols="3">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon color="success" @click="changeStatus(item, 2)" v-bind="attrs" v-on="on">
                      <v-icon>mdi-check-circle-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>تمت الطباعة</span>
                </v-tooltip>
              </v-col>
              <v-col cols="3">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon color="warning" @click="changeStatus(item, 1)" v-bind="attrs" v-on="on">
                      <v-icon>mdi-timer-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>قيد الطباعة</span>
                </v-tooltip>
              </v-col>

              <v-col cols="3">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon color="error" @click="changeStatus(item, 3)" v-bind="attrs" v-on="on">
                      <v-icon>mdi-close-circle-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>تم رفضها</span>
                </v-tooltip>
              </v-col>

              <v-col cols="3">
                <EditStudentInfo style="display: inline-block" :student="item" />
              </v-col>
            </v-row>
            <!-- <StatusModel :student="item" @reloadTask="getStudents()" /> -->
            <!-- <EditStudentInfo style="display: inline-block" /> -->
          </template>
        </v-data-table>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import StudentImage from "../components/StudentImage.vue";
import StatusModel from "../components/StatusModel.vue";
import FilterStudents from "../components/FilterStudents.vue";
import EditStudentInfo from "../components/EditStudentInfo.vue";
const Excel = require("exceljs");
import JSZip from "jszip";

export default {
  components: {
    StudentImage,
    StatusModel,
    FilterStudents,
    EditStudentInfo,
  },
  data: () => ({
    overlay: false,
    overlayImage: false,
    search: "",
    students: [],
    dataMessage: "الرجاء البحث عن طلبة",
    headers: [
      { text: "صورة الطالب", value: "photo" },
      { text: "اسم الطالب", value: "studentName" },
      { text: "الاسم انكليزي", value: "studentEnglishName" },
      { text: "الرقم الجامعي", value: "collegeNumber" },
      { text: "المواليد", value: "dob" },
      { text: "الجنس", value: "gender" },
      { text: "الجنسية", value: "nationality" },
      { text: "الكلية", value: "college" },
      { text: "القسم", value: "sectionName" },
      { text: "القسم انكليزي", value: "sectionEnglishName" },
      { text: "حالة الهوية", value: "statusId" },
      { text: "الشعبة", value: "studentClass" },
      { text: "ايميل الطالب", value: "studentEmail" },
      { text: "المرحلة", value: "studentLevel" },
      {
        text: "نوع الدراسة",
        value: "studyType",
      },
      { text: "الاجراءات", value: "actions", width: 200 },
    ],
  }),
  created() {
    this.getStudents();
  },
  methods: {
    getCollege(student) {
      switch (student.sectionName) {
        case "تقنية صناعة الاسنان":
        case "تقنيات التخدير":
        case "تقنيات المختبرات الطبية":
        case "تقنيات الاشعة":
        case "تقنيات البصريات":
          return "كلية التقنيات الصحية والطبية";
        case "التربية البدنية وعلوم الرياضة":
          return "كلية التربية البدنية";
        case "مالية ومصرفية":
        case "المحاسبة":
        case "ادارة اعمال":
        case "علوم السياحة و الفندقة":
          return "كلية الادارة والاقتصاد";
        case "هندسة تقنيات الحاسوب":
        case "تقنيات التبريد والتكيف":
        case "تقنيات اجهزة طبية":
        case "هندسة القدرة الكهربائية":
          return "الكلية التقنية الهندسية";
        case "الصيدلة":
          return "كلية الصيدلة";
        case "القانون":
          return "كلية القانون";
        case "اللغة الانكليزية":
          return "كلية الاداب";
        case "علوم الحاسوب":
        case "الادلة الجنائية":
        case "علوم الامن السيبراني":
        case "علوم الحياة":
        case "الذكاء الاصطناعي":
          return "كلية العلوم";
        case "الدراسات الاسلامية":
        case "العلوم النفسية والتربوية":
        case "العلوم التربوية والنفسية":
          return "كلية التربية";
        case "الطب الحياتي":
        case "هندسة تكرير النفط والغاز":
        case "تقنيات الطيران":
          return "كلية الهندسة";
        case "الاعلام":
          return "كلية الاعلام";
        case "التصميم":
          return "كلية الفنون";
        case "طب الاسنان":
          return "كلية طب الاسنان";
        case "التمريض":
          return "كلية التمريض";

        default:
          return " ";
      }
    },
    searchStudent() {
      let self = this;
      self.overlay = true;
      if (self.search) {
        self.$http
          .get(`${self.$store.state.apiUrl}/searchStudentIdentityForms/${self.search}`, {
            headers: { Authorization: `Bearer ${self.$store.state.user}` },
          })
          .then((res) => {
            self.overlay = false;
            self.students = res.data;
          })
          .catch((e) => {
            console.log(e);
            self.overlay = false;
          });
      } else {
        self.overlay = false;
      }
    },
    foundImage(image) {
      if (image.includes("https://")) {
        return true;
      } else {
        return false;
      }
    },
    async downloadImages() {
      this.overlay = true;
      const zip = new JSZip();
      const imagePromises = this.students.map(async (url, index) => {
        if (url.photoPath.includes("https://")) {
          const response = await this.$http.get(url.photoPath, { responseType: "blob" });
          zip.file(`${url.collegeNumber}.jpg`, response.data);
        } else {
          console.log("not found image");
        }
      });

      await Promise.all(imagePromises);
      zip.generateAsync({ type: "blob" }).then((blob) => {
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = "myimages.zip";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        this.overlay = false;
      });
    },
    getStudents() {
      let self = this;
      self.overlay = true;

      self.$http
        .all([
          self.$http.get(`${self.$store.state.apiUrl}/sections`, {
            headers: { Authorization: `Bearer ${self.$store.state.user}` },
          }),
        ])
        .then((responses) => {
          self.$store.state.sections = responses[0].data;
          self.overlay = false;
        })
        .catch((e) => {
          console.log(e);
          self.overlay = false;
        });
    },
    onSearch(value) {
      let self = this;
      console.log(value);
      let query = "";

      if (value.sectionName) {
        query += `&sectionName=${value.sectionName}`;
      }
      if (value.statusId) {
        query += `&statusId=${value.statusId}`;
      }

      self.$http
        .get(`${self.$store.state.apiUrl}/allStudentIdentityFormsSearch?1=1${query}`, {
          headers: { Authorization: `Bearer ${self.$store.state.user}` },
        })
        .then((res) => {
          console.log(res.data);
          self.students = res.data;
        });
    },
    changeStatus(student, statusId) {
      let self = this;
      self.$http.put(`${self.$store.state.apiUrl}/studentIdentityFormStatus/${student.idStudentIdentityForm}`, { statusId: statusId }).then((res) => {
        console.log(res.data);
        this.$toasted.success("تم تعديل البيانات");
      });
    },
    setColor(statusId) {
      if (statusId == 1) {
        return "border: 5px solid #fb8500; margin: 2px; border-radius: 100px";
      } else if (statusId == 2) {
        return "border: 5px solid #1b998b; margin: 2px; border-radius: 100px";
      } else {
        return "border: 5px solid #E0144C; margin: 2px; border-radius: 100px";
      }
    },

    exportExcelAll(students) {
      let self = this;
      const workbook = new Excel.Workbook();

      const worksheet = workbook.addWorksheet("allStudents");
      worksheet.views = [{ rightToLeft: true }];

      worksheet.columns = [
        { header: "اسم الطالب", key: "studentName", width: 15 },
        { header: "الاسم بالانكليزي", key: "studentEnglishName", width: 50 },
        { header: "القسم", key: "sectionName", width: 50 },
        { header: "الكلية", key: "college", width: 50 },
        { header: "القسم بالانكليزي", key: "sectionNameEnglish", width: 20 },
        { header: "المرحلة", key: "studentLevel", width: 50 },
        { header: "المواليد", key: "dob", width: 50 },
        { header: "الجنس", key: "gender", width: 50 },
        { header: "الجنسية", key: "nationality", width: 50 },
        { header: "الرقم الجامعي", key: "collegeNumber", width: 50 },
        { header: "نوع الدراسة", key: "studyType", width: 50 },
        { header: "ايميل الطالب", key: "studentEmail", width: 50 },
        { header: "الشعبة", key: "studentClass", width: 50 },
      ];

      students.forEach((student) => {
        worksheet.addRow({
          studentName: student.studentName.replace("0", ""),
          studentEnglishName: student.studentEnglishName,
          sectionName: student.sectionName,
          college: self.getCollege(student),
          sectionNameEnglish: student.sectionEnglishName,
          studentLevel: this.$options.filters.setLevel(student.studentLevel),
          dob: student.dob,
          gender: student.gender,
          nationality: student.nationality,
          collegeNumber: student.collegeNumber,
          studyType: student.studyType == 1 ? "صباحي" : "مسائي",
          studentEmail: student.studentEmail,
          studentClass: student.studentClass,
        });
      });

      worksheet.eachRow(function (row, rowNumber) {
        row.eachCell((cell) => {
          cell.alignment = { vertical: "middle", horizontal: "center" };
        });
      });

      worksheet.getRow(1).eachCell((cell) => {
        cell.font = { bold: true };
        cell.alignment = { vertical: "middle", horizontal: "center" };
        cell.fill = {
          type: "pattern",
          pattern: "darkTrellis",
          fgColor: { argb: "FFFFFF00" },
          bgColor: { argb: "FF0000FF" },
        };
      });

      workbook.xlsx.writeBuffer().then((data) => {
        const blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        document.body.appendChild(a);
        a.setAttribute("style", "display: none");
        a.href = url;
        a.download = `students.xlsx`;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
      });
    },
    exportExcel() {
      let self = this;
      const workbook = new Excel.Workbook();

      const worksheet = workbook.addWorksheet("allStudents");
      worksheet.views = [{ rightToLeft: true }];

      worksheet.columns = [
        { header: "اسم الطالب", key: "studentName", width: 15 },
        { header: "الاسم بالانكليزي", key: "studentEnglishName", width: 50 },
        { header: "القسم", key: "sectionName", width: 50 },
        { header: "الكلية", key: "college", width: 50 },
        { header: "القسم بالانكليزي", key: "sectionNameEnglish", width: 20 },
        { header: "المرحلة", key: "studentLevel", width: 50 },
        { header: "المواليد", key: "dob", width: 50 },
        { header: "الجنس", key: "gender", width: 50 },
        { header: "الجنسية", key: "nationality", width: 50 },
        { header: "الرقم الجامعي", key: "collegeNumber", width: 50 },
        { header: "نوع الدراسة", key: "studyType", width: 50 },
        { header: "ايميل الطالب", key: "studentEmail", width: 50 },
        { header: "الشعبة", key: "studentClass", width: 50 },
      ];

      self.students.forEach((student) => {
        worksheet.addRow({
          studentName: student.studentName.replace("0", ""),
          studentEnglishName: student.studentEnglishName,
          sectionName: student.sectionName,
          college: self.getCollege(student),
          sectionNameEnglish: student.sectionEnglishName,
          studentLevel: this.$options.filters.setLevel(student.studentLevel),
          dob: student.dob,
          gender: student.gender,
          nationality: student.nationality,
          collegeNumber: student.collegeNumber,
          studyType: student.studyType == 1 ? "صباحي" : "مسائي",
          studentEmail: student.studentEmail,
          studentClass: student.studentClass,
        });
      });

      worksheet.eachRow(function (row, rowNumber) {
        row.eachCell((cell) => {
          cell.alignment = { vertical: "middle", horizontal: "center" };
        });
      });

      worksheet.getRow(1).eachCell((cell) => {
        cell.font = { bold: true };
        cell.alignment = { vertical: "middle", horizontal: "center" };
        cell.fill = {
          type: "pattern",
          pattern: "darkTrellis",
          fgColor: { argb: "FFFFFF00" },
          bgColor: { argb: "FF0000FF" },
        };
      });

      workbook.xlsx.writeBuffer().then((data) => {
        const blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        document.body.appendChild(a);
        a.setAttribute("style", "display: none");
        a.href = url;
        a.download = `students.xlsx`;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
      });
    },
  },
  filters: {
    setLevel: function (level) {
      if (level == 1) {
        return "المرحلة الاولى";
      } else if (level == 2) {
        return "المرحلة الثانية";
      } else if (level == 3) {
        return "المرحلة الثالثة";
      } else if (level == 4) {
        return "المرحلة الرابعة";
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
