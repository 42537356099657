<template>
  <div>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <v-container class="container" fluid>
      <v-card elevation="6">
        <v-row style="margin: 10px">
          <v-col cols="10">
            <h3>الخدمة السريعة</h3>
          </v-col>
        </v-row>
      </v-card>
    </v-container>

    <v-container class="container" fluid>
      <v-card elevation="6">
        <v-card-title>
          <v-text-field v-model="search" outlined append-icon="mdi-magnify" label="بحث" single-line hide-details></v-text-field>
        </v-card-title>
        <v-data-table :headers="headers" :items="students" :items-per-page="30" :search="search" item-key="idStudent" class="table-content" :no-data-text="dataMessage">
          <template v-slot:[`item.photo`]="{ item }">
            <ServiceImage :image="`${$store.state.apiUrl}/${item.studentImage}`" :student="item" style="display: inline-block" />
          </template>
          <template v-slot:[`item.gender`]="{ item }">
            <h4 style="padding: 0 3px">
              {{ item.gender }}
            </h4>
          </template>

          <template v-slot:[`item.statusId`]="{ item }">
            <h4 style="padding: 0 3px">
              {{ item.statusId == 1 ? "قيد الطباعة" : item.statusId == 2 ? "طبعت" : "تم الرفض" }}
            </h4>
          </template>

          <template v-slot:[`item.studentLevel`]="{ item }">
            <h4 style="padding: 0 3px">
              {{ item.studentLevel | setLevel }}
            </h4>
          </template>

          <template v-slot:[`item.studyType`]="{ item }">
            <h4 style="padding: 0 3px">
              {{ item.studyType == 1 ? "صباحي" : "مسائي" }}
            </h4>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <OtherPhotos :student="item" style="display: inline-block" />
          </template>
        </v-data-table>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import ServiceImage from "../components/ServiceImage.vue";
import OtherPhotos from "../components/OtherPhotos.vue";

export default {
  components: {
    ServiceImage,
    OtherPhotos,
  },
  data: () => ({
    overlay: false,
    overlayImage: false,
    search: "",
    students: [],
    dataMessage: "الرجاء البحث عن طلبة",
    headers: [
      { text: "صورة الطالب", value: "photo" },
      { text: "اسم الطالب", value: "studentName" },
      { text: "القسم", value: "sectionName" },
      { text: "رقم الهاتف", value: "phoneNumber" },
      { text: "المرحلة", value: "studentLevel" },
      { text: "نوع المستند", value: "documentType" },
      { text: "معنون الى", value: "toDocument" },
      { text: "مواليد الطالب", value: "studentDob" },
      { text: "محل الولادة", value: "birthPlace" },
      { text: "عدد النسخ", value: "numberOfCopy" },
      { text: "سعر المستند", value: "documentPrice" },
      { text: "الملاحظات", value: "notes" },

      { text: "الاجراءات", value: "actions", width: 200 },
    ],
  }),
  created() {
    this.getStudents();
  },

  methods: {
    getStudents() {
      let self = this;
      self.overlay = true;

      self.$http
        .all([
          self.$http.get(`${self.$store.state.apiUrl}/fastServices`, {
            headers: { Authorization: `Bearer ${self.$store.state.user}` },
          }),
        ])
        .then((responses) => {
          console.log(responses);
          self.students = responses[0].data;

          self.overlay = false;
        })
        .catch((e) => {
          console.log(e);
          self.overlay = false;
        });
    },
  },
  filters: {
    setLevel: function (level) {
      if (level == 1) {
        return "المرحلة الاولى";
      } else if (level == 2) {
        return "المرحلة الثانية";
      } else if (level == 3) {
        return "المرحلة الثالثة";
      } else if (level == 4) {
        return "المرحلة الرابعة";
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
